import axios from '@/utils/api.request'
export const getVerifiCode = (data) => {
    return axios.request({
      url: '/personal/sendDxVerifiCode',
      method: 'post',
      data
    })
}
export const login = (data) => {
    return axios.request({
      url: '/personal/H5Login',
      method: 'post',
      data
    })
}
export const getUserInfo = (data) => {
    return axios.request({
      url: '/personal/queryPersonalDetail',
      method: 'post',
      data
    })
}
export const updateUserInfo = (data) => {
    return axios.request({
      url: '/personal/editPersonalDetail',
      method: 'post',
      data
    })
}

export const getMeetOrders = (data) => {
    return axios.request({
      url: '/personal/queryMeetOrderList',
      method: 'post',
      data
    })
}
export const getMeetOrderInfo = (data) => {
    return axios.request({
      url: '/personal/queryMeetOrderDetail',
      method: 'post',
      data
    })
}
export const applyRefund = (data) => {
    return axios.request({
      url: '/personal/applyToRefund',
      method: 'post',
      data
    })
}
export const getRefundRecords = (data) => {
  return axios.request({
    url: '/personal/queryRefundList',
    method: 'post',
    data
  })
}
export const getGoodInfo = (data) => {
  return axios.request({
    url: '/personal/queryGoodsInfo',
    method: 'post',
    data
  })
}
export const applyInvoice = (data) => {
  return axios.request({
    url: '/personal/comfirmBlueInvoice',
    method: 'post',
    data
  })
}

export const getInvoiceList = (data) => {
  return axios.request({
    url: '/personal/list',
    method: 'post',
    data
  })
}
export const getInvoice = (data) => {
  return axios.request({
    url: '/personal/getInvoiceDetail',
    method: 'post',
    data
  })
}
export const emailInvoice = (data) => {
  return axios.request({
    url: '/personal/reSend',
    method: 'post',
    data
  })
}
export const checkInvoice = (data) => {
  return axios.request({
    url: '/personal/checkDate',
    method: 'post',
    data
  })
}


