<template>
  <div class="applyPage">
    <Cell v-if="refundType=='meet'" title="退费记录" is-link @click="goRecordPage"/>
    <Field
      v-if="refundType=='meet'"
      readonly
      clickable
      name="picker"
      :value="selectCount"
      label="选择退费人"
      placeholder="请选择退费人"
      right-icon="arrow"
      input-align="right"
      @click="showPicker = true"
      :rules="[{ required: true, message: '请选择退费人' }]"
    />
    <Field
      style="border-radius:5px"
      v-model.trim="reason"
      rows="6"
      autosize
      label=""
      type="textarea"
      maxlength="300"
      show-word-limit
      placeholder="请输入退费申请原因"
    />
    <Button v-if="orderStatus==2" class="mar-t-25" block color="#7f7f7f"
      >提交申请</Button
    >
    <Button v-else class="mar-t-25" block color="#2b72ff" @click="submit"
      >提交申请</Button
    >
    <Popup v-model="showPicker" position="bottom">
      <div class="btns">
        <span @click="showPicker = false">取消</span>
        <span>退费名单</span>
        <span class="yes" @click="confirm">确定</span>
      </div>
      <div class="content">
        <checkbox-group v-model="result">
          <cell-group>
            <Cell
              v-for="(item, index) in pickerList"
              clickable
              :key="item.id"
              :title="item.name"
              @click="toggle(item,index)"
            >
              <template #right-icon>
                <Checkbox :name="item" ref="checkboxes"  :disabled="(item.refundStatus==1)||(item.refundStatus==2)"/>
              </template>
            </Cell>
          </cell-group>
        </checkbox-group>
      </div>
    </Popup>
  </div>
</template>

<script>
import {
  Field,
  Button,
  Notify,
  Popup,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Toast
} from "vant";
import {applyRefund} from "@/api/my.js"
import {memberApplyRefund} from "@/api/member.js"
import {getAttenderList} from "@/api/meet.js"


export default {
  name: "applyRefund",
  components: {
    Field,
    Button,
    Popup,
    [Notify.Component.name]: Notify.Component,
    Cell,
    CellGroup,
    Checkbox,
    CheckboxGroup,
    Toast
  },
  data() {
    return {
      orderId:'',
      reason: "",
      selectList: [],
      result: [],
      showPicker: false,
      pickerList: [],
      orderStatus:'',//订单状态不是已退回的时候才能提交
    };
  },
  computed: {
    selectCount() {
      if (this.selectList.length > 0) {
        return this.selectList.length + "人";
      } else {
        return "";
      }
    },
  },
  created(){
    this.orderId=this.$route.query.orderId;
    this.refundType=this.$route.query.type;//退费类型 meet或者member
    if(this.$route.query.type=='meet'){
      this.getPersonList();
    }
    this.orderStatus=this.$route.query.orderStatus?this.$route.query.orderStatus:''
  },
  methods: {
    getPersonList(){
      getAttenderList({orderId:this.orderId}).then(res=>{
        if(res.status=="S"){
          this.pickerList=[...res.data]
        }
      })
    },
    toggle(item,index) {
      if(item.refundStatus=='0'){
        this.$refs.checkboxes[index].toggle();
      }
    },
    submit() {
      if (!this.reason) {
        Notify({ type: "warning", message: "退费申请原因不能为空！" });
        return false;
      } else {
        if(this.refundType=="meet"){
          if(this.selectList.length>0){
            let attenderIds=this.selectList.map(item=>{
              return item.id
            })
            attenderIds=attenderIds.join(",");
            let data={applyReason:this.reason,attenderIds,orderId:this.orderId}
            applyRefund(data).then(res=>{
              if(res.status=="S"){
                Toast.success('提交成功！');

                this.$router.push("/myMeetDetail?orderId="+this.orderId)
              }
            })
          }else{
            Notify({ type: "warning", message: "请选择退费人员！" });
            return false;
          }
        } else{
          let data={applyReason:this.reason,orderId:this.orderId}
          memberApplyRefund(data).then(res=>{
            if(res.status=="S"){
              this.$router.push("/memberRecord?orderId="+this.orderId)
            }
          })
        }
      }
    },
    confirm() {
      this.selectList = [...this.result];
      this.showPicker = false;
    },
    goRecordPage(){
      this.$router.push(`/refundRecords?orderId=${this.orderId}`)
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep.van-cell{
  padding:16px;
}
.applyPage {
  width: 100vw;
  height: 100vh;
  background: #f5f5f5;
  padding: 0.2rem 0.12rem;
  box-sizing: border-box;
}
.mar-t-25 {
  border-radius: 5px;
  height: 0.42rem;
  margin-top: 0.5rem;
}
.btns {
  width: 100%;
  height: 0.44rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #969799;
  font-size: 14px;
  border-bottom: 1px solid #f5f5f5;
  span {
    display: inline-block;
    width: 0.6rem;
    height: 0.44rem;
    text-align: center;
    line-height: 0.44rem;
  }
  .yes {
    color: #576b95;
  }
}
.content {
  padding: 0rem 0.13rem 0.2rem;
}
</style>
