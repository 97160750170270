import axios from '@/utils/api.request'

export const getMemberInfo = (data) => {
    return axios.request({
      url: '/clientInfo/selectClientInfo',
      method: 'post',
      data
    })
}

export const getTypeList = (data) => {
  return axios.request({
    url: '/memberType/memberTypeList',
    method: 'post',
    data
  })
}

export const getPayRecord = (data) => {
    return axios.request({
      url: '/memberPayInfo/memberPayInfoList',
      method: 'post',
      data
    })
}
export const memberPay = (data) => {
    return axios.request({
      url: '/clientInfo/saveClientInfo',
      method: 'post',
      data
    })
}
export const getOrgList = (data) => {
    return axios.request({
      url: '/memberType/branchDeptList',
      method: 'post',
      data
    })
}
export const memberApplyRefund = (data) => {
    return axios.request({
      url: '/memberPayInfo/applyToRefund',
      method: 'post',
      data
    })
}
export const payMember = (data) => {
  return axios.request({
    url: '/clientInfo/saveMemberPayInfo',
    method: 'post',
    data
  })
}
export const getMemberOrder = (data) => {
  return axios.request({
    url: '/memberPayInfo/queryMemberPayInfoDetail',
    method: 'post',
    data
  })
}

