import axios from '@/utils/api.request'
export const getMeetList = (params) => {
    return axios.request({
      url: '/meetInfo/meetInfoLists',
      method: 'post',
      data:params
    })
}
export const getMeetInfo = (params) => {
    return axios.request({
      url: '/meetInfo/selectMeetInfo',
      method: 'post',
      data:params
    })
}
export const delAttender = (params) => {
    return axios.request({
      url: '/mobile/delMeetingAttender',
      method: 'post',
      data:params
    })
}

export const getMeetOrder = (params) => {
    return axios.request({
      url: '/mobile/getOrderIdByMeetId',
      method: 'post',
      data:params
    })
}
export const getAttenderList = (params) => {
    return axios.request({
      url: '/mobile/getClientListByOrderId',
      method: 'post',
      data:params
    })
}
export const generateMeetOrder = (params) => {
    return axios.request({
      url: '/mobile/addMeetingAttender',
      method: 'post',
      data:params
    })
}
//生成0元订单
export const generateMeetOrderZero = (params) => {
  return axios.request({
    url: '/mobile/addMeetingAttenderByZero',
    method: 'post',
    data:params
  })
}
